/** @format */

import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material";

import mobileImg from "../../assets/images/loginpage.png";
import desktopImg from "../../assets/images/loginimage.png";
import FormInput from "../../forms/FormInput";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import SettingContext from "../../context/SettingsContext";
import { post } from "../../services/apiMethods";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${desktopImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  height: "100vh",
  "@media (max-width: 768px)": {
    // image for mobile view
    backgroundImage: `url(${mobileImg})`,
  },
}));

const InnerBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
}));

const FormCard = styled(Card)(({ theme }) => ({
  textAlign: "center",
  padding: "10px",
  borderRadius: "5px",
}));

export default function ForgotPassword() {
  const { selectedSetting } = useContext(SettingContext);
  const [loading, setLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState("employee");
  const [otpSent, setOtpSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handeSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        schoolId: selectedSetting._id,
      };
      setLoading(true);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const entryFormik = useFormik({
    initialValues: {
      userType: "",
      contactNumber: "",
    },
    onSubmit: handeSubmit,
  });

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  const getOtp = () => {
    try {
      setOtpSent(true);
    } catch (error) {
      console.log(error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <OuterBox>
        <InnerBox>
          <FormCard sx={{ minWidth: 375, maxWidth: 340, pb: 4 }}>
            <CardContent>
              <Typography
                sx={{ fontSize: 22, fontWeight: 600, mb: 3 }}
                gutterBottom>
                Forgot Password
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} my={2}>
                  <ButtonGroup
                    size="small"
                    fullWidth
                    variant="contained"
                    aria-label="Basic button group">
                    <Button
                      variant={
                        selectedButton === "employee" ? "contained" : "outlined"
                      }
                      onClick={() => handleButtonClick("employee")}>
                      Employee
                    </Button>
                    <Button
                      size="small"
                      variant={
                        selectedButton === "student" ? "contained" : "outlined"
                      }
                      onClick={() => handleButtonClick("student")}>
                      Student
                    </Button>
                    <Button
                      variant={
                        selectedButton === "parent" ? "contained" : "outlined"
                      }
                      onClick={() => handleButtonClick("parent")}>
                      Parent
                    </Button>
                    <Button
                      variant={
                        selectedButton === "alumni" ? "contained" : "outlined"
                      }
                      onClick={() => handleButtonClick("alumni")}>
                      Alumni
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>

              {otpSent ? (
                <>
                  {" "}
                  <FormInput
                    required={true}
                    name="otp"
                    formik={entryFormik}
                    label="OTP"
                  />
                  <FormInput
                    required={true}
                    name="newPassword"
                    formik={entryFormik}
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      style: {
                        borderWidth: 1,
                        height: "42px",
                        borderRadius: 5,
                      },

                      endAdornment: (
                        <InputAdornment position="end" sx={{ pr: 1 }}>
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end">
                            {showPassword ? (
                              <VisibilityOff sx={{ color: "grey" }} />
                            ) : (
                              <Visibility sx={{ color: "grey" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              ) : (
                <>
                  <FormInput
                    required={true}
                    name="username"
                    formik={entryFormik}
                    label="User Name"
                  />
                </>
              )}
            </CardContent>
            {!otpSent ? (
              <LoadingButton
                loading={loading}
                fullWidth
                size="small"
                onClick={getOtp}
                variant="contained">
                Get OTP
              </LoadingButton>
            ) : (
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={entryFormik.handleSubmit}>
                Submit
              </Button>
            )}
            <Button
              fullWidth
              size="small"
              color="error"
              onClick={() => navigate("/login")}
              variant="contained"
              sx={{ mt: 2 }}>
              cancel
            </Button>
          </FormCard>
        </InnerBox>
      </OuterBox>
    </>
  );
}
