/** @format */

import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, styled } from "@mui/material";
import themeData from "../data/themeData";

const MuiBox = styled(Box)(({ theme, bgcolor }) => ({
  background: bgcolor,
  overflow: "hidden",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  position: "relative",
}));

const SplashNewsHorizontal = ({ horizontalData = [], selectedTheme }) => {
  let text = horizontalData.map((d) => `${d.text}`);
  const location = useLocation();

  if (
    location.pathname.startsWith("/sch") ||
    location.pathname.startsWith("/login") ||
    location.pathname.startsWith("/forgot-password")
  ) {
    return null;
  }

  const backgroundColor =
    selectedTheme === 3 ? "#000724" : themeData.darkPalette.primary.main;

  return (
    <MuiBox
      sx={{
        bgcolor: "#000724",
      }}>
      <Typography
        component="div"
        sx={{
          fontSize: "16px",
          p: 1,
          color: "#fff",
          display: "inline-block",
          animation: "marquee 25s linear infinite",
          minWidth: "100%",
        }}>
        {text.join(" || ")}
        &nbsp;&nbsp;&nbsp; {text.join(" || ")}{" "}
      </Typography>

      {/* CSS for animation */}
      <style>
        {`
        @keyframes marquee {
          from { transform: translateX(0); }
          to { transform: translateX(-100%); }
        }
      `}
      </style>
    </MuiBox>
  );
};

export default SplashNewsHorizontal;
